<script setup>
import Button from "@Components/Button.vue";
import {formatForMoney, getProductThumbnailUrl, notify} from "@Res/js/helpers";
import {onMounted, ref, watch} from "vue";
import debounce from "lodash.debounce";
import {router, usePage} from "@inertiajs/vue3";
import Tooltip from "@Components/Tooltip.vue";
import ALink from "@Components/ALink.vue";
import clampJs from 'clamp-js';

const props = defineProps({
    item: Object
})

const loading = ref(false)
const deleting = ref(false)
const quantity = ref(props.item.quantity)
const showMaxProductLimitTooltip = ref(false)
const itemNameRef = ref(null)

const updateQuantity = () => {
    // Return if no change occurred
    if (props.item.quantity === quantity.value) return

    router.post(route("storefront.cart-item.update-quantity", {
        store_slug: usePage().props.store.slug,
        uuid: props.item.uuid,
        quantity: quantity.value,
    }), {}, {
        onBefore: () => loading.value = true,
        onError: (error) => notify({text: Object.values(error)[0], type: "error"}),
        onFinish: () => loading.value = false
    });
}

const removeItem = () => {
    if (deleting.value || loading.value) return

    router.delete(route("storefront.cart-item.delete", {
        store_slug: usePage().props.store.slug,
        uuid: props.item.uuid,
    }), {}, {
        onBefore: () => deleting.value = true,
        onSuccess: () => notify({text: usePage().props.status, type: "success"}),
        onError: (error) => notify({text: Object.values(error)[0], type: "error"}),
        onFinish: () => deleting.value = false
    });
}

watch(quantity, () => showMaxProductLimitTooltip.value = quantity.value === props.item.product.quantity)
watch(quantity, debounce(updateQuantity, 1200))

onMounted(() => {
    clampJs(itemNameRef.value, {clamp: 2})
})
</script>

<template>
    <li>
        <img :src="getProductThumbnailUrl(item.product)" alt="N/A"
             class="size-10 rounded-lg object-cover object-center border border-gray-300"/>

        <div class="ml-4 flex flex-1 flex-col space-y-2">
            <div>
                <div class="flex justify-between font-medium text-gray-900">
                    <ALink :href="route('storefront.product', {store_slug: item.store.slug, slug: item.product.slug})"
                           size="sm">
                        <span ref="itemNameRef" class="font-normal leading-5 transition-colors"
                              v-text="item.product.name"/>
                    </ALink>

                    <p class="ml-4 text-sm whitespace-nowrap"
                       v-text="formatForMoney(item.price, $page.props.store.currency)"/>
                </div>
                <p v-if="item.coupon" class="mt-1 text-sm text-gray-500" v-text="item.coupon"/>
            </div>

            <div class="flex flex-1 items-end justify-between text-sm">
                <div v-if="item.product.quantity" class="flex items-center space-x-2">
                    <p class="text-gray-500 text-sm" v-text="`Qty:`"/>

                    <div
                        class="flex items-center rounded-xl border border-gray-300">
                        <button
                            class="size-6 text-gray-600 transition hover:opacity-75"
                            type="button"
                            @click="quantity <= 1 ? () => {} : quantity--"
                        >
                            &minus;
                        </button>

                        <Tooltip :show="showMaxProductLimitTooltip"
                                 content="Product limit reached."
                                 time="3"
                                 @timeout="showMaxProductLimitTooltip = false">
                            <input
                                v-model="quantity"
                                class="h-6 w-12 border-y-0 border-gray-300 text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none"
                                type="number"
                                @keyup.enter="updateQuantity"
                            />
                        </Tooltip>

                        <button
                            class="size-6 text-gray-600 transition hover:opacity-75"
                            type="button"
                            @click="item.product.quantity > 0 && item.product.quantity <= quantity ? () => {} : quantity++"
                        >
                            &plus;
                        </button>
                    </div>

                    <svg v-if="loading" class="size-4 animate-spin">
                        <use href="#icon-spinner"/>
                    </svg>
                </div>

                <div class="flex-1 text-right">
                    <Button :disabled="deleting || loading" kind="error" size="sm" type="link" @click="removeItem">
                        Remove
                    </Button>
                </div>
            </div>
        </div>
    </li>
</template>

